@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  body {
    @apply px-2;
    @apply bg-zinc-900 text-stone-200;
    @apply text-center;
  }
}
